import { graphql, Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogPostCard from "../components/BlogPostCard";
import { Section } from "../components/Core";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import imgMetaCardSquare from "../assets/images/card_homepage_square.jpg";
import imgMetaCardTwitter from "../assets/images/card_homepage_twitter.jpg";
import he from 'he'

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMdx.nodes
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/blog' : '/blog/' + (currentPage - 1).toString()
    const nextPage = '/blog/' + (currentPage + 1).toString()

    return (
      <>
        <PageWrapper>
          <Seo
            title="Sharptree | Blog | Mobile Reimagined"
            description=""
            image={imgMetaCardSquare}
            twitterImage={imgMetaCardTwitter}
            pathname={this.props.location.pathname}
          />
          <PageHero title="Blog" />

          <Section className="position-relative">
            <Container>
              <Row className="align-items-top justify-content-left">

                {posts.map(({ frontmatter, fields }) => (

                  <Col lg="4" className="mb-5">
                    <BlogPostCard
                      imgFluid={frontmatter.featuredImage.childImageSharp.fluid}
                      title={he.decode(frontmatter.title)}
                      date={frontmatter.date}
                      slug={fields.slug}
                      readMore
                    >
                      {he.decode(frontmatter.summary)}
                    </BlogPostCard>
                  </Col>
                ))}

              </Row>

              {numPages > 1 && (
                <Row className="align-items-center justify-content-center">
                  <ul
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      listStyle: 'none',
                      padding: 0,
                    }}
                  >
                    {!isFirst && (
                      <Link to={prevPage} rel="prev">
                        ←
                      </Link>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                      <li
                        key={`pagination-number${i + 1}`}
                        style={{
                          margin: 0,
                        }}
                      >
                        <Link
                          to={`/blog/${i === 0 ? '' : i + 1}`}
                          style={{
                            padding: 12,
                            // color: i + 1 === currentPage ? '#ffffff' : '',
                            // background: i + 1 === currentPage ? '#007acc' : '',
                          }}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    {!isLast && (
                      <Link to={nextPage} rel="next">
                        →
                      </Link>
                    )}
                  </ul>
                </Row>
              )}
            </Container>
          </Section>
        </PageWrapper>
      </>
    )
  }
}

export const blogListQuery = graphql
  `
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: {published: {eq: true}} }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "LL")
          summary
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`